import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { UtilService } from 'src/app/services/util.service';
import { ActionType } from 'src/app/shared/AppConstants';
import { AddLocationCategoryComponent } from '../add-location-category/add-location-category.component';

export interface DialogData {
  mode: any;
  id: string;
  allReadyHasRootLoaction: boolean;
}

@Component({
  selector: 'app-modify-location',
  templateUrl: './add-sub-location.component.html',
  styleUrls: ['./add-sub-location.component.scss'],
})
export class AddSubLocationComponent implements OnInit {
  public title: string = '';
  public formGroup: FormGroup;
  public errorMsg: string = '';
  public locationCategories: any[] = [];
  public isShowProgressBar: boolean = false;
  public hide: boolean = false;
  public prefixLocation: string = '';

  constructor(
    public locationService: LocationService,
    public authService: AuthService,
    public utilService: UtilService,
    public router: Router,
    public dialog: MatDialog,
    public dialog1: NgDialogAnimationService,
    public locationDialogRef: MatDialogRef<AddSubLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.formGroup = new FormGroup({
      loactionId: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      category: new FormControl('', [Validators.required]),
    });
    if (this.data?.mode == 'sub') {
      this.prefixLocation = this.data?.id;
      this.title = 'Add Sub-Location';
    } else {
      this.title = 'Add Location';
    }
  }

  ngOnInit(): void {
    this.getLocationCategories();
  }

  closeDialog(): void {
    this.locationDialogRef.close(false);
  }

  getLocationCategories() {
    this.isShowProgressBar = true;
    this.locationCategories = [];
    this.locationService.getLocationCategories().subscribe(
      (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response.body) {
            // Assign the data
            this.locationCategories = response.body;
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId');
          localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          this.errorMsg = response.message;
        } else {
          this.errorMsg = response.message;
        }
      },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.errorMsg = error.error;
          } else {
            this.errorMsg = error.error;
          }
        }
    );
  }

  async saveLocation() {
    this.isShowProgressBar = true;
    let body: any = {
      LOCATION_ID: this.formGroup.controls.loactionId.value,
      NAME: this.formGroup.controls.name.value,
      CATEGORY_ID: this.formGroup.controls.category.value,
      DESCRIPTION: this.formGroup.controls.description.value,
    };

    if (this.data.mode == 'sub') {
      body.LOCATION_ID = body.LOCATION_ID;
      body.PARENT_LOCATION_ID = this.prefixLocation;
    }

    await this.locationService
      .addLocation(body)
      .toPromise()
      .then(
        (response: any) => {
          this.isShowProgressBar = false;
          if (response.status === 204) {
            if (this.data.mode == 'sub') {
              this.locationDialogRef.close(
                body.PARENT_LOCATION_ID + '-' + body.LOCATION_ID
              );
            } else {
              this.locationDialogRef.close(true);
            }
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          this.errorMsg = error.error;
        }
      );
  }

  editCategory() {
    let category: any = [];
    if (this.locationCategories && this.locationCategories.length > 0) {
      let that = this;
      category = this.locationCategories.filter(function (e) {
        return e.CATEGORY_ID == that.formGroup.controls.category.value;
      });
      category = category?.length > 0 ? category[0] : {};
    }
    const dialogRef = this.dialog.open(AddLocationCategoryComponent, {
      width: '350px',
      panelClass: 'modify-customer-dialog',
      // animation: { to: 'left' },
      position: { right: '0' },
      data: {
        mode: ActionType.Edit,
        item: category,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.getLocationCategories();
      }
    });
  }

  addCategory() {
    const dialogRef = this.dialog.open(AddLocationCategoryComponent, {
      width: '350px',
      panelClass: 'modify-customer-dialog',
      // animation: { to: 'left' },
      position: { right: '0' },
      data: { mode: ActionType.Add, item: {} },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.getLocationCategories();
        this.formGroup.controls.category.setValue(result);
      }
    });
  }
}
