import { TimedServicesComponent } from './pages/timed-services/timed-services.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymousGuard } from './anonymous.guard';
import { AuthGuard } from './auth.guard';
import { ExtensionsComponent } from './pages/extensions/extensions.component';
import { AuditScheduleComponent } from './pages/audit-schedule/audit-schedule.component';
import { AuditsComponent } from './pages/audits/audits.component';
import { ChoicesComponent } from './pages/choices/choices.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { LoginComponent } from './pages/login/login.component';
import { LopaComponent } from './pages/lopa/lopa.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PartsComponent } from './pages/parts/parts.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ServiceRequestsComponent } from './pages/service-requests/service-requests.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { UsersComponent } from './pages/users/users.component';
import { RoleGuard } from './role.guard';
import { ScheduledServiceRequestComponent } from './pages/scheduled-service-request/scheduled-service-request.component';
import { ConfigManagementComponent } from './pages/config-management/config-management.component';
import { BudgetComponent } from './pages/budget/budget.component';
import { ReportDashboardComponent } from './pages/report-dashboard/report-dashboard.component';
import { SubAuditsComponent } from './pages/sub-audits/sub-audits.component';
import { EquipmentCategoryComponent } from './pages/equipment-category/equipment-category.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { PartOrdersComponent } from './pages/part-orders/part-orders.component';
import { BillableReportsComponent } from './pages/billable-reports/billable-reports.component';
import { TasksComponent } from './pages/tasks/tasks/tasks.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [AnonymousGuard],
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
    ],
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { role: 'ADMIN' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'reports',
    component: ReportDashboardComponent,
    data: { role: 'ADMIN' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'customers',
    component: CustomersComponent,
    data: { role: 'CUSTOMER' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'users',
    component: UsersComponent,
    data: { role: 'USER' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'audits',
    component: AuditsComponent,
    data: { role: 'AUDIT' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'sub-audits',
    component: SubAuditsComponent,
    data: { role: 'AUDIT' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'audit-schedule',
    component: AuditScheduleComponent,
    data: { role: 'AUDIT' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'service-requests',
    component: ServiceRequestsComponent,
    data: { role: 'SERVICE_REQUEST' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'timed-service-schedules',
    component: ScheduledServiceRequestComponent,
    data: { role: 'SCHEDULED_SERVICE_REQUEST' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'timed-services',
    component: TimedServicesComponent,
    data: { role: 'SCHEDULED_SERVICE_REQUEST' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    data: { role: 'MESSAGE' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'locations',
    component: LocationsComponent,
    data: { role: 'EQUIPMENT' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'parts',
    component: PartsComponent,
    data: { role: 'EQUIPMENT' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { role: 'SETTINGS' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'equipment-category',
    component: EquipmentCategoryComponent,
    data: { role: 'SETTINGS' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'choices',
    component: ChoicesComponent,
    data: { role: 'SETTINGS' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'extensions',
    component: ExtensionsComponent,
    data: { role: 'SETTINGS' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'configurations',
    component: ConfigManagementComponent,
    data: { role: 'SETTINGS' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'budget',
    component: BudgetComponent,
    data: { role: 'BUDGET' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'lopa',
    component: LopaComponent,
    data: { role: 'LOPA' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'invoice',
    component: InvoiceComponent,
    data: { role: 'INVOICE' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'part-orders',
    component: PartOrdersComponent,
    data: { role: 'PART_ORDER' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'billable-reports',
    component: BillableReportsComponent,
    data: { role: 'BILLABLE_UNIT_REPORT' },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'tasks',
    component: TasksComponent,
    data: { role: 'EVENT_TASKS' },
    canActivate: [AuthGuard, RoleGuard],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
