import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PartService } from 'src/app/services/part.service';
import { Router } from '@angular/router';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AddNewPartComponent } from 'src/app/components/add-new-part/add-new-part.component';
import { InventoryComponent } from 'src/app/components/inventory/inventory.component';
import { PartFilterComponent } from 'src/app/components/part-filter/part-filter.component';
import { ServiceRequestService } from 'src/app/services/service-request.service';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.scss'],
})
export class PartsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public tableColumns: string[] = [
    'PART_ID',
    'NAME',
    'DESCRIPTION',
    'MANUFACTURER',
    'MFG_PART_NUMBER',
    'MFG_PART_NUMBER_2',
    'MFG_PART_NUMBER_3',
    'PART_TYPE',
    'UOM',
    'Min',
    'Max',
    'TOTAL',
    'QUANTITY',
    'AVG_PRICE',
    'Actions',
  ];

  public isSearchHasValue: boolean;
  public partsDataSource: MatTableDataSource<any>;
  public partsLength: number;
  public paginatorLength: number;
  public constants: AppConstants;
  public loggedInCustomerId: any;
  public timeout: any = null;
  public version: string;
  public release: string;
  public isShowPartsProgressBar: boolean;
  public subscriptions: Subscription[] = [];
  public searchTerm: string;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public partService: PartService,
    public router: Router,
    public dialog: MatDialog,
    public serviceRequestService: ServiceRequestService
  ) {
    this.getScreenHeight();
    this.isSearchHasValue = false;
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.isShowPartsProgressBar = false;
    this.partsLength = 0;
    this.searchTerm = '';
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.partsDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.getParts(this.constants.INITIAL_PAGINATOR_LIMIT, 0);
  }

  ngOnDestroy() {
    this.cancelOutstandingRequests();
    this.serviceRequestService.filterDetails = null;
  }

  cancelOutstandingRequests() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  logout() {
    this.authService.logout();
  }

  getParts(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    type?: any,
    count?: any,
    manufacturer?: any
  ) {
    this.isShowPartsProgressBar = true;
    let PartsSubscription = this.partService
      .getParts(
        limit,
        offset,
        searchText,
        sortField,
        sortDirection,
        type,
        count,
        manufacturer
      )
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.partsDataSource = new MatTableDataSource(
                response.body?.PARTS
              );
              this.partsLength = response.body?.PARTS_COUNT;
            }
            this.isShowPartsProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowPartsProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowPartsProgressBar = false;
            this.showErrorDialog(response.error);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowPartsProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowPartsProgressBar = false;
            this.showErrorDialog(error.message);
          }
        }
      );
    this.subscriptions.push(PartsSubscription);
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;

    let type = '';
    if (
      this.serviceRequestService.filterDetails?.type &&
      this.serviceRequestService.filterDetails?.type != ''
    ) {
      type = this.serviceRequestService.filterDetails?.type;
    }

    let count = '';
    if (
      this.serviceRequestService.filterDetails?.count &&
      this.serviceRequestService.filterDetails?.count != ''
    ) {
      count = this.serviceRequestService.filterDetails?.count;
    }

    let manufacturer = '';
    if (
      this.serviceRequestService.filterDetails?.manufacturer &&
      this.serviceRequestService.filterDetails?.manufacturer != ''
    ) {
      manufacturer = this.serviceRequestService.filterDetails?.manufacturer;
    }

    this.getParts(
      this.paginatorLength,
      offset,
      '',
      e.active,
      e.direction,
      type,
      count,
      manufacturer
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  searchPartsFilter(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      let type = '';
      if (
        $this.serviceRequestService.filterDetails?.type &&
        $this.serviceRequestService.filterDetails?.type != ''
      ) {
        type = $this.serviceRequestService.filterDetails?.type;
      }

      let count = '';
      if (
        $this.serviceRequestService.filterDetails?.count &&
        $this.serviceRequestService.filterDetails?.count != ''
      ) {
        count = $this.serviceRequestService.filterDetails?.count;
      }

      let manufacturer = '';
      if (
        $this.serviceRequestService.filterDetails?.manufacturer &&
        $this.serviceRequestService.filterDetails?.manufacturer != ''
      ) {
        manufacturer = $this.serviceRequestService.filterDetails?.manufacturer;
      }

      $this.getParts(
        $this.constants.INITIAL_PAGINATOR_LIMIT,
        0,
        event.target.value,
        '',
        '',
        type,
        count,
        manufacturer
      );
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  clearSearch() {
    this.searchTerm = '';
    let type = '';
    if (
      this.serviceRequestService.filterDetails?.type &&
      this.serviceRequestService.filterDetails?.type != ''
    ) {
      type = this.serviceRequestService.filterDetails?.type;
    }

    let count = '';
    if (
      this.serviceRequestService.filterDetails?.count &&
      this.serviceRequestService.filterDetails?.count != ''
    ) {
      count = this.serviceRequestService.filterDetails?.count;
    }

    let manufacturer = '';
    if (
      this.serviceRequestService.filterDetails?.manufacturer &&
      this.serviceRequestService.filterDetails?.manufacturer != ''
    ) {
      manufacturer = this.serviceRequestService.filterDetails?.manufacturer;
    }

    this.getParts(
      this.constants.INITIAL_PAGINATOR_LIMIT,
      0,
      '',
      '',
      '',
      type,
      count,
      manufacturer
    );
    this.paginator.pageIndex = 0;
  }

  //Paginator page items handle
  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;

    let type = '';
    if (
      this.serviceRequestService.filterDetails?.type &&
      this.serviceRequestService.filterDetails?.type != ''
    ) {
      type = this.serviceRequestService.filterDetails?.type;
    }

    let count = '';
    if (
      this.serviceRequestService.filterDetails?.count &&
      this.serviceRequestService.filterDetails?.count != ''
    ) {
      count = this.serviceRequestService.filterDetails?.count;
    }

    let manufacturer = '';
    if (
      this.serviceRequestService.filterDetails?.manufacturer &&
      this.serviceRequestService.filterDetails?.manufacturer != ''
    ) {
      manufacturer = this.serviceRequestService.filterDetails?.manufacturer;
    }

    if (this.sort.active) {
      this.getParts(
        limit,
        offset,
        '',
        this.sort.active,
        this.sort._direction,
        type,
        count,
        manufacturer
      );
    } else {
      this.getParts(limit, offset, '', '', '', type, count);
    }
  }

  refreshParts() {
    this.cancelOutstandingRequests();
    this.serviceRequestService.filterDetails = null;
    this.paginator.pageIndex = 0;
    this.getParts(this.constants.INITIAL_PAGINATOR_LIMIT, 0);
  }

  addNewPart() {
    const dialogRef = this.dialog.open(AddNewPartComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { mode: ActionType.Add, item: {} },
      panelClass: 'add-customer-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let type = '';
        if (
          this.serviceRequestService.filterDetails?.type &&
          this.serviceRequestService.filterDetails?.type != ''
        ) {
          type = this.serviceRequestService.filterDetails?.type;
        }

        let count = '';
        if (
          this.serviceRequestService.filterDetails?.count &&
          this.serviceRequestService.filterDetails?.count != ''
        ) {
          count = this.serviceRequestService.filterDetails?.count;
        }
        let manufacturer = '';
        if (
          this.serviceRequestService.filterDetails?.manufacturer &&
          this.serviceRequestService.filterDetails?.manufacturer != ''
        ) {
          manufacturer = this.serviceRequestService.filterDetails?.manufacturer;
        }
        this.paginator.pageIndex = 0;
        this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
        this.getParts(
          this.paginatorLength,
          0,
          '',
          '',
          '',
          type,
          count,
          manufacturer
        );
      }
    });
  }

  updatePart(part: any) {
    const dialogRef = this.dialog.open(AddNewPartComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { mode: ActionType.Edit, item: part },
      panelClass: 'add-customer-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let offset =
        this.paginator._pageIndex * this.paginator._pageSize;

        let type = '';
        if (
          this.serviceRequestService.filterDetails?.type &&
          this.serviceRequestService.filterDetails?.type != ''
        ) {
          type = this.serviceRequestService.filterDetails?.type;
        }

        let count = '';
        if (
          this.serviceRequestService.filterDetails?.count &&
          this.serviceRequestService.filterDetails?.count != ''
        ) {
          count = this.serviceRequestService.filterDetails?.count;
        }
        let manufacturer = '';
        if (
          this.serviceRequestService.filterDetails?.manufacturer &&
          this.serviceRequestService.filterDetails?.manufacturer != ''
        ) {
          manufacturer = this.serviceRequestService.filterDetails?.manufacturer;
        }
        if (this.sort.active) {
          this.getParts(
            this.paginatorLength,
            offset,
            '',
            this.sort.active,
            this.sort._direction,
            type,
            count,
            manufacturer
          );
        } else {
          this.getParts(
            this.paginatorLength,
            offset,
            '',
            '',
            '',
            type,
            count,
            manufacturer
          );
        }
        this.paginator.pageIndex = this.paginator._pageIndex;
      }
    });
  }

  deactivatePart(part: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to deactivate this part?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body = {
          PART_ID: part.PART_ID,
          INACTIVE: 'Y',
        };

        await this.partService
          .updatePart(body)
          .toPromise()
          .then(
            (response: any) => {
              if (response.status === 204) {
                let offset =
                  this.paginator._pageIndex * this.paginator._pageSize;

                let type = '';
                if (
                  this.serviceRequestService.filterDetails?.type &&
                  this.serviceRequestService.filterDetails?.type != ''
                ) {
                  type = this.serviceRequestService.filterDetails?.type;
                }

                let count = '';
                if (
                  this.serviceRequestService.filterDetails?.count &&
                  this.serviceRequestService.filterDetails?.count != ''
                ) {
                  count = this.serviceRequestService.filterDetails?.count;
                }
                let manufacturer = '';
                if (
                  this.serviceRequestService.filterDetails?.manufacturer &&
                  this.serviceRequestService.filterDetails?.manufacturer != ''
                ) {
                  manufacturer =
                    this.serviceRequestService.filterDetails?.manufacturer;
                }

                if (this.sort.active) {
                  this.getParts(
                    this.paginatorLength,
                    offset,
                    '',
                    this.sort.active,
                    this.sort._direction,
                    type,
                    count,
                    manufacturer
                  );
                } else {
                  this.getParts(
                    this.paginatorLength,
                    offset,
                    '',
                    '',
                    '',
                    type,
                    count,
                    manufacturer
                  );
                }
                this.paginator.pageIndex = this.paginator._pageIndex;
              } else if (response.status === 401) {
                // Redirect to login for unauthorized
                localStorage.removeItem('token');
                localStorage.removeItem('user-details');
                localStorage.removeItem('customerName');
                localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
                this.authService.loggedIn.next(false);
                this.router.navigate(['login']);
              } else if (response.status === 403) {
                this.showErrorDialog(response.error);
              } else {
                this.showErrorDialog(
                  `Error while deactivating part - ${response.error}`
                );
              }
            },
            (error) => {
              if (error.status === 401) {
                // Redirect to login for unauthorized
                localStorage.removeItem('token');
                localStorage.removeItem('user-details');
                localStorage.removeItem('customerName');
                localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
                this.authService.loggedIn.next(false);
                this.router.navigate(['login']);
              } else if (error.status === 403) {
                this.showErrorDialog(error.error);
              } else {
                this.showErrorDialog(
                  `Error while deactivating part - ${error.message}`
                );
              }
            }
          );
      }
    });
  }

  activatePart(part: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to activate this part?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body = {
          PART_ID: part.PART_ID,
          INACTIVE: 'N',
        };

        await this.partService
          .updatePart(body)
          .toPromise()
          .then(
            (response: any) => {
              if (response.status === 204) {
                let offset =
                  this.paginator._pageIndex * this.paginator._pageSize;

                let type = '';
                if (
                  this.serviceRequestService.filterDetails?.type &&
                  this.serviceRequestService.filterDetails?.type != ''
                ) {
                  type = this.serviceRequestService.filterDetails?.type;
                }

                let count = '';
                if (
                  this.serviceRequestService.filterDetails?.count &&
                  this.serviceRequestService.filterDetails?.count != ''
                ) {
                  count = this.serviceRequestService.filterDetails?.count;
                }
                let manufacturer = '';
                if (
                  this.serviceRequestService.filterDetails?.manufacturer &&
                  this.serviceRequestService.filterDetails?.manufacturer != ''
                ) {
                  manufacturer =
                    this.serviceRequestService.filterDetails?.manufacturer;
                }

                if (this.sort.active) {
                  this.getParts(
                    this.paginatorLength,
                    offset,
                    '',
                    this.sort.active,
                    this.sort._direction,
                    type,
                    count,
                    manufacturer
                  );
                } else {
                  this.getParts(
                    this.paginatorLength,
                    offset,
                    '',
                    '',
                    '',
                    type,
                    count
                  );
                }
                this.paginator.pageIndex = this.paginator._pageIndex;
              } else if (response.status === 401) {
                // Redirect to login for unauthorized
                localStorage.removeItem('token');
                localStorage.removeItem('user-details');
                localStorage.removeItem('customerName');
                localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
                this.authService.loggedIn.next(false);
                this.router.navigate(['login']);
              } else if (response.status === 403) {
                this.showErrorDialog(response.error);
              } else {
                this.showErrorDialog(
                  `Error while activating part - ${response.error}`
                );
              }
            },
            (error) => {
              if (error.status === 401) {
                // Redirect to login for unauthorized
                localStorage.removeItem('token');
                localStorage.removeItem('user-details');
                localStorage.removeItem('customerName');
                localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
                this.authService.loggedIn.next(false);
                this.router.navigate(['login']);
              } else if (error.status === 403) {
                this.showErrorDialog(error.error);
              } else {
                this.showErrorDialog(
                  `Error while activating part - ${error.message}`
                );
              }
            }
          );
      }
    });
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  addInventory(item: any) {
    const dialogRef = this.dialog.open(InventoryComponent, {
      width: '470px',
      panelClass: 'add-customer-dialog',
      data: { mode: ActionType.Add, item: item },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        let offset =
        this.paginator._pageIndex * this.paginator._pageSize;
        let type = '';
        if (
          this.serviceRequestService.filterDetails?.type &&
          this.serviceRequestService.filterDetails?.type != ''
        ) {
          type = this.serviceRequestService.filterDetails?.type;
        }

        let count = '';
        if (
          this.serviceRequestService.filterDetails?.count &&
          this.serviceRequestService.filterDetails?.count != ''
        ) {
          count = this.serviceRequestService.filterDetails?.count;
        }
        let manufacturer = '';
        if (
          this.serviceRequestService.filterDetails?.manufacturer &&
          this.serviceRequestService.filterDetails?.manufacturer != ''
        ) {
          manufacturer = this.serviceRequestService.filterDetails?.manufacturer;
        }

        if (this.sort.active) {
          this.getParts(
            this.paginatorLength,
            offset,
            '',
            this.sort.active,
            this.sort._direction,
            type,
            count,
            manufacturer
          );
        } else {
          this.getParts(
            this.paginatorLength,
            offset,
            '',
            '',
            '',
            type,
            count,
            manufacturer
          );
        }
        this.paginator.pageIndex = this.paginator._pageIndex;
      }
    });
  }

  deleteInventory(item: any) {
    const dialogRef = this.dialog.open(InventoryComponent, {
      width: '470px',
      panelClass: 'add-customer-dialog',
      data: { mode: ActionType.Edit, item: item },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        let type = '';
        if (
          this.serviceRequestService.filterDetails?.type &&
          this.serviceRequestService.filterDetails?.type != ''
        ) {
          type = this.serviceRequestService.filterDetails?.type;
        }

        let count = '';
        if (
          this.serviceRequestService.filterDetails?.count &&
          this.serviceRequestService.filterDetails?.count != ''
        ) {
          count = this.serviceRequestService.filterDetails?.count;
        }
        let manufacturer = '';
        if (
          this.serviceRequestService.filterDetails?.manufacturer &&
          this.serviceRequestService.filterDetails?.manufacturer != ''
        ) {
          manufacturer = this.serviceRequestService.filterDetails?.manufacturer;
        }

        let offset =
        this.paginator._pageIndex * this.paginator._pageSize;

        if (this.sort.active) {
          this.getParts(
            this.paginatorLength,
            offset,
            '',
            this.sort.active,
            this.sort._direction,
            type,
            count,
            manufacturer
          );
        } else {
          this.getParts(
            this.paginatorLength,
            offset,
            '',
            '',
            '',
            type,
            count,
            manufacturer
          );
        }
        this.paginator.pageIndex = this.paginator._pageIndex;
      }
    });
  }

  filterParts() {
    let tempFilterDetails;
    if (
      this.serviceRequestService.filterDetails &&
      this.serviceRequestService.filterDetails != null &&
      this.serviceRequestService.filterDetails != undefined
    ) {
      tempFilterDetails = this.serviceRequestService.filterDetails;
    }

    let dialogRef = this.dialog.open(PartFilterComponent, {
      width: '300px',
      data: { item: tempFilterDetails },
      hasBackdrop: true,
      panelClass: 'service-request-filter',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.partsDataSource = new MatTableDataSource();
        this.paginator.pageIndex = 0;
        this.serviceRequestService.filterDetails = result.data;

        let type = '';
        if (result?.data?.type && result?.data?.type != '') {
          type = result?.data?.type;
        }

        let count = '';
        if (result?.data?.count && result?.data?.count != '') {
          count = result?.data?.count;
        }

        let manufacturer = '';
        if (result?.data?.manufacturer && result?.data?.manufacturer != '') {
          manufacturer = result?.data?.manufacturer;
        }

        this.getParts(
          this.paginatorLength,
          0,
          '',
          '',
          '',
          type,
          count,
          manufacturer
        );
      }
    });
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
         this.refreshParts();
        }
      });
    }
  }
}
