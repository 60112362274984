import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { AuthService } from 'src/app/services/auth.service';
import { TaskService } from 'src/app/services/tasks.service';
import { UtilService } from 'src/app/services/util.service';
import { AppConstants } from 'src/app/shared/AppConstants';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;
  public isAirlineCusromer: boolean = false;
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public tableColumns: string[] = [
    'TASK_ID',
    'EQUIPMENT_NAME',
    'CATEGORY_NAME',
    'LOCATION',
    'TASK_TYPE',
    'CREATED_BY_NAME',
    'CREATED_AT'
  ];
  public appropriateClass: string = '';
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any) {
    if (window.innerHeight <= 700) {
      this.appropriateClass = 'bottomRelative';
    } else {
      this.appropriateClass = 'bottomStick';
    }
  }

  public version: string;
  public release: string;
  public constants: AppConstants;
  public isShowTasksProgressBar: boolean = false;
  public tasksDataSource: MatTableDataSource<any>;
  public paginatorLength: number;
  public tasksLength: number = 0;
  public subscriptions: Subscription[] = [];
  public searchTerm: string = '';
  public isSearchHasValue: boolean;
  public timeout: any = null;
  public loggedInUserId: any;

  constructor(public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public dialog: MatDialog,
    public router: Router,
    public taskService: TaskService) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.tasksDataSource = new MatTableDataSource();
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.isSearchHasValue = false;
  }

  ngOnInit(): void {
    let customerId: any = localStorage.getItem('customerId');
    if (customerId && customerId != null) {
      if (customerId == 1) {
        this.authService.isAFSUser = true;
      } else {
        this.authService.isAFSUser = false;
      }
    }
    this.getTasks(this.paginatorLength, 0, '', '', 'desc');
    let user: any = localStorage.getItem('user-details');
    if (user && user != null) {
      user = JSON.parse(user);
      this.isAirlineCusromer = user.CUSTOMER_TYPE == 'AIRLINE' ? true : false;
      this.loggedInUserId = user.USER_ID;
    }
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.refreshTasks();
        }
      });
    }
  }

  logout() {
    this.authService.logout();
  }

  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getTasks(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
        this.sort.active,
        this.sort._direction
      );
    } else {
      this.getTasks(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '', '', 'desc'
      );
    }
  }

  refreshTasks() {
    this.searchTerm = '';
    this.cancelOutstandingRequests();
    this.paginator.pageIndex = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.getTasks(this.paginatorLength, 0, '', '', 'desc');
  }

  clearSearch() {
    this.cancelOutstandingRequests();
    this.searchTerm = '';
    this.paginator.pageIndex = 0;
    this.getTasks(this.paginatorLength, 0, '', '', 'desc');
  }

  cancelOutstandingRequests() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getTasks(
      this.paginatorLength,
      offset,
      this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
      e.active,
      e.direction
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  searchInvoiceFilter(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if ($this.sort.active) {
        $this.getTasks(
          $this.paginatorLength,
          0,
          event.target.value,
          $this.sort.active,
          $this.sort._direction
        );
      } else {
        $this.getTasks($this.paginatorLength, 0, event.target.value, '', 'desc');
      }
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  getTasks(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    this.isShowTasksProgressBar = true;
    let usersSubscription = this.taskService
      .getTasks(limit, offset, searchText, sortField, sortDirection)
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              response?.body?.EVENT_TASKS.forEach((invoice: any) => {
                invoice.CREATED_AT = invoice.CREATED_AT.replace('Z', '')
              });
              this.tasksDataSource = new MatTableDataSource(
                response.body.EVENT_TASKS
              );
              this.tasksLength = response.body.EVENT_TASK_COUNT;
            }
            this.isShowTasksProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowTasksProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowTasksProgressBar = false;
            this.errorDialog(response.error);
          }
        },
        (error: any) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowTasksProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowTasksProgressBar = false;
            this.errorDialog(error.message);
          }
        }
      );
    this.subscriptions.push(usersSubscription);
  }

  errorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }
}

