<mat-toolbar class="app-toolbar-fixed">
  <span> {{title}} </span>
  <span class="example-spacer"></span>
  <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>

<form style="padding: 20px;font-size: 14px !important;" [formGroup]="formGroup">
  <mat-form-field appearance="legacy" *ngIf="data.mode != 'sub'">
    <mat-label>Id</mat-label>
    <input autocomplete="off" matInput type="text" required formControlName="loactionId">
  </mat-form-field>

  <mat-form-field appearance="legacy" *ngIf="data.mode == 'sub'">
    <mat-label>Id</mat-label>
    <span matPrefix *ngIf="hide || formGroup.controls.loactionId.valid">{{prefixLocation}}-</span>
    <input autocomplete="off" matInput type="text" (focusin)="hide = true" (focusout)="hide = false" required
      formControlName="loactionId">
  </mat-form-field>

  <mat-form-field appearance="legacy">
    <mat-label>Name</mat-label>
    <input autocomplete="off" matInput type="text" required formControlName="name">
  </mat-form-field>

  <div style="display:flex">
    <mat-form-field appearance="legacy">
      <mat-label>Category</mat-label>
      <mat-select formControlName="category" required disableOptionCentering>
        <mat-option [disabled]="cat.CATEGORY_ID !== 'W' && data.allReadyHasRootLoaction" *ngFor="let cat of locationCategories" [value]="cat.CATEGORY_ID">
          {{cat.NAME}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-icon-button matTooltip="Add Category" (click)="addCategory()" [disabled]="data.allReadyHasRootLoaction">
      <mat-icon [ngClass]="data.allReadyHasRootLoaction ? 'material-icons-outlined font-size-30' : 'material-icons-outlined add-button font-size-30'">add
      </mat-icon>
    </button>

    <button mat-icon-button matTooltip="Edit Category" (click)="editCategory()"
      [disabled]="!formGroup.controls.category.valid || data.allReadyHasRootLoaction">
      <mat-icon [ngClass]="data.allReadyHasRootLoaction ? 'material-icons-outlined font-size-30' : 'material-icons-outlined add-button font-size-30'">edit
      </mat-icon>
    </button>
  </div>


  <mat-form-field appearance="legacy">
    <mat-label>Description</mat-label>
    <input autocomplete="off" matInput type="text" formControlName="description">
  </mat-form-field>

  <div style="text-align:center;padding-top:10px">
    <button mat-raised-button [ngClass]="formGroup.valid ? 'primary-button' : ''" [disabled]="!formGroup.valid"
      (click)="saveLocation()">
      Save
    </button>
  </div>

  <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
    <p>{{errorMsg}}</p>
  </div>
</form>
