import { ConfigService } from 'src/app/services/config.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  mode: any;
  item: any;
  type: any;
  selectedObj: any;
}

@Component({
  selector: 'app-add-task-type',
  templateUrl: './add-task-type.component.html',
  styleUrls: ['./add-task-type.component.scss']
})
export class AddTaskTypeComponent implements OnInit {
  public title: string = '';
  public formGroup: FormGroup = new FormGroup({});
  public errorMsg: string = '';
  public isShowProgressBar: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddTaskTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public configService: ConfigService) { }

  ngOnInit(): void {
    this.title = this.data?.type == 'add' ? 'Add New Task Type' : 'Update Task Type';
    if (this.data?.type == 'add') {
      this.formGroup = new FormGroup({
        description: new FormControl(''),
        taskType: new FormControl('', [Validators.required])
      });
    } else {
      this.formGroup = new FormGroup({
        description: new FormControl(
          { value: this.data?.selectedObj?.DESCRIPTION, disabled: false },
          [Validators.required]
        ),
        taskType: new FormControl(
          { value: this.data?.selectedObj?.TASK_TYPE, disabled: true },
          [Validators.required]
        ),
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  async save() {
    if (this.data?.type == 'add') {
      let body: any = {
        EQUIPMENT_CATEGORY_ID: this.data.item.CATEGORY_ID,
        TASK_TYPE: this.formGroup.controls.taskType.value.trim(),
        DESCRIPTION: this.formGroup.controls.description.value.trim(),
      };
      this.isShowProgressBar = true;
      await this.configService
        .saveTaskType(body)
        .toPromise()
        .then(
          (response) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.dialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    } else {
      this.isShowProgressBar = true;
      let body: any = {
        EQUIPMENT_CATEGORY_ID: this.data.item.CATEGORY_ID,
        TASK_TYPE: this.formGroup.controls.taskType.value.trim(),
        DESCRIPTION: this.formGroup.controls.description.value.trim(),
      };
      await this.configService
        .updateTaskType(body)
        .toPromise()
        .then(
          (response) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.dialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    }
  }
}
