import { ConfigService } from 'src/app/services/config.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddChoiceValueComponent } from 'src/app/components/add-choice-value/add-choice-value.component';
import { AddNewChoiceCategoryComponent } from 'src/app/components/add-new-choice-category/add-new-choice-category.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { AuthService } from 'src/app/services/auth.service';
import { ChoicesService } from 'src/app/services/choices.service';
import { UtilService } from 'src/app/services/util.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';
import { Location } from '@angular/common';
import { LocationService } from 'src/app/services/location.service';
import { AddAttributeComponent } from 'src/app/components/add-attribute/add-attribute.component';
import { AddEquipmentCategoryComponent } from 'src/app/components/add-equipment-category/add-equipment-category.component';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AddNewBomComponent } from 'src/app/components/add-new-bom/add-new-bom.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { AddTaskTypeComponent } from 'src/app/components/add-task-type/add-task-type.component';

@Component({
  selector: 'app-equipment-category',
  templateUrl: './equipment-category.component.html',
  styleUrls: ['./equipment-category.component.scss'],
})
export class EquipmentCategoryComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public version: string;
  public release: string;
  public constants: AppConstants;
  public userCompanyList: any[] = [];
  public selectedCustomer: any;
  public isShowCategoryProgressBar: boolean = false;
  public isShowValuesProgressBar: boolean = false;
  public isShowSubValuesProgressBar: boolean = false;
  public categoryList: any = {};
  public equipmentCategoryList: any[] = [];
  public selectedCategoryIndex: any = null;
  public selectedValueIndex: any = null;
  public selectedCategoryValues: any[] = [];
  public selectedCategorySubValues: any[] = [];
  public selectedCategoryItem: any = null;
  public selectedCategoryValueItem: any = null;
  public attributesList: any[] = [];
  public bomsList: any[] = [];
  public tasksList: any[] = [];
  public isShowVattributesProgressBar: boolean = false;
  public isShowBomProgressBar: boolean = false;
  public appropriateClass: string = '';
  public events: string[] = [];
  public isShowTaskTypeProgressBar: boolean = false;

  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any) {
    if (window.innerHeight <= 700) {
      this.appropriateClass = 'bottomRelative';
    } else {
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    private _location: Location,
    public authService: AuthService,
    public utilService: UtilService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private choiceService: ChoicesService,
    public dialog: MatDialog,
    public locationService: LocationService,
    private configService: ConfigService
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
  }

  ngOnInit(): void {
    this.selectedCustomer = localStorage.getItem('customerId');
    if (this.selectedCustomer && this.selectedCustomer != null) {
      this.authService.isAFSUser = this.selectedCustomer == 1 ? true : false;
    }
    this.getCustomization();
  }

  logout() {
    this.authService.logout();
  }

  async selectedCategory(index: any, item: any) {
    this.selectedCategoryItem = item;
    this.selectedCategoryIndex = index;
    this.selectedValueIndex = null;
    await this.getTaskTypesList();
    await this.getEquipmentCategoryAttributesByCategoryId();
    await this.getBomsList();

  }

  refreshCategories() {
    this.selectedValueIndex = null;
    this.equipmentCategoryList = [];
    this.selectedCategoryIndex = null;
    this.selectedCategoryItem = null;
    this.getCustomization();
    this.bomsList = [];
  }

  async getCustomization() {
    this.isShowCategoryProgressBar = true;
    await this.configService
      .getEquipmentCategories()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowCategoryProgressBar = false;
          if (response.status === 200) {
            if (response?.body) {
              this.equipmentCategoryList = response?.body?.EQUIPMENT_CATEGORIES;
            } else {
              this.equipmentCategoryList = [];
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowCategoryProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowCategoryProgressBar = false;
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowCategoryProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowCategoryProgressBar = false;
            this.showErrorAlertDialog(error.message);
          }
        }
      );
  }

  showErrorAlertDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  addAttribute(type: string, selectedObj: any) {
    const dialogRef = this.dialog.open(AddAttributeComponent, {
      width: '550px',
      data: {
        mode: '',
        item: this.selectedCategoryItem,
        type: type,
        selectedObj: selectedObj,
      },
      panelClass: 'add-customer-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.getEquipmentCategoryAttributesByCategoryId();
      }
    });
  }

  goBack() {
    this._location.back();
  }

  addCategory() {
    const dialogRef = this.dialog.open(AddEquipmentCategoryComponent, {
      width: '550px',
      data: { mode: ActionType.Add, item: {} },
      panelClass: 'equipment-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.getCustomization();
        if (this.selectedCategoryItem?.CATEGORY_ID) {
          await this.getTaskTypesList();
          await this.getEquipmentCategoryAttributesByCategoryId();
          await this.getBomsList();
        }
      }
    });
  }

  updateCategory(category: any) {
    const dialogRef = this.dialog.open(AddEquipmentCategoryComponent, {
      width: '550px',
      data: { mode: ActionType.Edit, item: category },
      panelClass: 'equipment-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.getCustomization();
      }
    });
  }

  selectedAttribute(index: any) {
    this.selectedValueIndex = index;
  }

  deleteAttribute(item: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to delete this attribute?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body: any = { CATEGORY_ID: item?.CATEGORY_ID, ATTRIBUTE_NAME: item?.ATTRIBUTE_NAME };
        await this.configService
          .deleteAttribute(body)
          .toPromise()
          .then(
            async (response: any) => {
              if (response.status === 204) {
                await this.getEquipmentCategoryAttributesByCategoryId();
              } else {
                this.showErrorAlertDialog(response?.message);
              }
            },
            (error) => {
              this.showErrorAlertDialog(error?.message);
            }
          );
      }
    });
  }

  async getEquipmentCategoryAttributesByCategoryId() {
    this.attributesList = [];
    this.isShowVattributesProgressBar = true;
    await this.configService
      .getEquipmentCategoryAttributesByCategoryId(this.selectedCategoryItem?.CATEGORY_ID)
      .toPromise()
      .then(
        (response: any) => {
          this.isShowVattributesProgressBar = false;
          if (response.status === 200) {
            this.attributesList = response.body;
          } else {
            this.isShowVattributesProgressBar = false;
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowVattributesProgressBar = false;
          this.showErrorAlertDialog(error.error);
        }
      );
  }

  async getBomsList() {
    this.isShowBomProgressBar = true;
    this.bomsList = [];
    await this.configService
      .bomequipmentcategorymap(this.selectedCategoryItem?.CATEGORY_ID)
      .toPromise()
      .then(
        (response: any) => {
          this.isShowBomProgressBar = false;
          if (response.status === 200) {
            this.bomsList = response.body;
          } else {
            this.isShowBomProgressBar = false;
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowBomProgressBar = false;
          this.showErrorAlertDialog(error.error);
        }
      );
  }
  addNewBom() {
    const dialogRef = this.dialog.open(AddNewBomComponent, {
      width: '550px',
      data: {
        item: this.selectedCategoryItem,
        list: this.bomsList
      },
      panelClass: 'add-customer-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.getBomsList();
      }
    });
  }

  deleteBom(item: any) {
    if (this.bomsList.length == 1) {
      this.showErrorAlertDialog('Cannot un-assaign this BOM. At-least one BOM should be assigned to the equipment category.');
    } else {
      const dialogRef = this.dialog.open(AlertComponent, {
        width: '400px',
        data: { errorMsg: 'Are you sure you want to unlink this BOM for this equipment category?' },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result.data) {
          let body: any = { EQUIPMENT_CATEGORY_ID: this.selectedCategoryItem?.CATEGORY_ID, BOM_IDS: [] };
          let filteredBomsList = this.bomsList.filter(bom => bom.BOM_ID != item.BOM_ID)
          if (filteredBomsList.length > 0) {
            body.BOM_IDS = filteredBomsList.map((el) => el.BOM_ID)
          }
          await this.configService
            .addBomEquipmentCategoryMap(body)
            .toPromise()
            .then(
              async (response: any) => {
                if (response.status === 204) {
                  await this.getBomsList();
                } else {
                  this.showErrorAlertDialog(response?.message);
                }
              },
              (error) => {
                this.showErrorAlertDialog(error?.message);
              }
            );
        }
      });
    }
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.selectedCustomer = localStorage.getItem('customerId');
          if (this.selectedCustomer && this.selectedCustomer != null) {
            this.authService.isAFSUser = this.selectedCustomer == 1 ? true : false;
          }
          this.getCustomization();
        }
      });
    }
  }

  addTaskType(type: string, selectedObj: any) {
    const dialogRef = this.dialog.open(AddTaskTypeComponent, {
      width: '550px',
      data: {
        mode: '',
        item: this.selectedCategoryItem,
        type: type,
        selectedObj: selectedObj,
      },
      panelClass: 'add-customer-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.getTaskTypesList();
      }
    });
  }

  deleteTaskType(item: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to delete this task type?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body: any = { EQUIPMENT_CATEGORY_ID: item?.EQUIPMENT_CATEGORY_ID, TASK_TYPE: item?.TASK_TYPE };
        await this.configService
          .deleteTaskType(body)
          .toPromise()
          .then(
            async (response: any) => {
              if (response.status === 204) {
                await this.getTaskTypesList();
              } else {
                this.showErrorAlertDialog(response?.message);
              }
            },
            (error) => {
              this.showErrorAlertDialog(error?.message);
            }
          );
      }
    });
  }

  async getTaskTypesList() {
    this.isShowTaskTypeProgressBar = true;
    this.tasksList = [];
    await this.configService
      .getTasksTyepes()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowTaskTypeProgressBar = false;
          if (response.status === 200) {
            this.tasksList = response.body[this.selectedCategoryItem?.CATEGORY_ID] || [];
          } else {
            this.isShowTaskTypeProgressBar = false;
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowTaskTypeProgressBar = false;
          this.showErrorAlertDialog(error.error);
        }
      );
  }
}
