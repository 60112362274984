<mat-toolbar class="toolbar">
    <button mat-icon-button (click)="authService.isExpanded = !authService.isExpanded">
        <mat-icon>menu</mat-icon>
    </button>
    {{utilService.customerName}}
    <div class="company-logo">
        <button mat-button routerLink="/dashboard">
            <img class="company-image" src="assets/logo/AFS_PrimaryLogo_1C_White.png" />
        </button>
    </div>
    <span class="example-spacer"></span>
    <button matTooltip="Switch Company" mat-icon-button *ngIf="authService.isAfsUser()" (click)="switchCompany()">
        <mat-icon style="font-size: 29px;" class="material-icons-outlined">swap_horiz</mat-icon>
    </button> &nbsp;
    <button matTooltip="Logout" mat-icon-button (click)="logout()">
        <mat-icon class="material-icons-outlined">logout</mat-icon>
    </button>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" autosize [hasBackdrop]="false">
    <!--#region SideNav -->
    <mat-sidenav #drawer [fixedInViewport]="true" [disableClose]="true" [fixedTopGap]="55"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'side' : 'side'"
        [opened]="true">
        <div class="side-menu-height">
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN')  && authService.isNotAfsCustomer()">
                <a mat-list-item [routerLink]="['/dashboard']" matTooltip="Dashboard" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">dashboard</mat-icon>
                    <span [translate]="'Dashboard'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Dashboard</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN') && authService.isNotAfsCustomer()">
                <a mat-list-item [routerLink]="['/reports']" matTooltip="Reports" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">auto_awesome_mosaic</mat-icon>
                    <span [translate]="'Reports'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Reports</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('CUSTOMER')">
                <a mat-list-item [routerLink]="['/customers']" matTooltip="Customers" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon>corporate_fare</mat-icon>
                    <span [translate]="'Customers'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Customers</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('USER')">
                <a mat-list-item [routerLink]="['/users']" matTooltip="Users" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">people</mat-icon>
                    <span [translate]="'Users'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Users</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/service-requests']" matTooltipPosition="right"
                    matTooltip="Service Requests" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">content_paste</mat-icon>
                    <span [translate]="'Service Requests'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Service
                        Requests</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/timed-services']" matTooltipPosition="right"
                    matTooltip="Timed Services" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">timer</mat-icon>
                    <span [translate]="'Timed Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Timed
                        Services</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/timed-service-schedules']" matTooltipPosition="right"
                    matTooltip="Timed Service Schedules" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons">pending_actions</mat-icon>
                    <span [translate]="'Timed Service Schedules'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Timed Service Schedules</span>
                </a>
            </mat-nav-list>
            <mat-nav-list
                *ngIf="(authService.checkRoleBasedModuleAccess('MESSAGE') || authService.checkRoleBasedModuleAccess('SERVICE_REQUEST'))">
                <a mat-list-item [routerLink]="['/notifications']" matTooltip="Notifications" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">feedback</mat-icon>
                    <span [translate]="'Notifications'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Notifications</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
                <a mat-list-item [routerLink]="['/audits']" matTooltip="Services" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon>checklist_rtl</mat-icon>
                    <span [translate]="'Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing"> Services
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
                <a mat-list-item [routerLink]="['/audit-schedule']" matTooltip="Scheduled Services"
                    matTooltipPosition="right" routerLinkActive="active-list-item">
                    <mat-icon>schedule</mat-icon>
                    <span [translate]="'Scheduled Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">
                        Scheduled Services
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('LOPA')">
                <a mat-list-item [routerLink]="['/lopa']" matTooltip="LOPA Management" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">flight</mat-icon>
                    <span [translate]="'Lopa'" *ngIf="authService.isExpanded" class="menu-icon-spacing">LOPA Management
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
                <a mat-list-item [routerLink]="['/locations']" matTooltip="Locations" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">my_location</mat-icon>
                    <span [translate]="'Locations'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Locations
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
                <a mat-list-item [routerLink]="['/parts']" matTooltip="Parts" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">chair_alt</mat-icon>
                    <span [translate]="'Parts'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Parts
                    </span>
                </a>
            </mat-nav-list>

            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BUDGET')">
                <a mat-list-item [routerLink]="['/budget']" matTooltip="Budget" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">card_travel</mat-icon>
                    <span [translate]="'Budget'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Budget
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('INVOICE')">
                <a mat-list-item [routerLink]="['/invoice']" matTooltip="Invoice" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">description</mat-icon>
                    <span [translate]="'Invoice'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Invoice
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('PART_ORDER') || authService.checkRoleBasedModuleAccess('PART_ORDER_READ_ONLY')">
                <a mat-list-item [routerLink]="['/part-orders']" matTooltip="Part Orders" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">add_shopping_cart</mat-icon>
                    <span [translate]="'Part Orders'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Part
                        Orders
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT') || authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT_READ_ONLY')">
                <a mat-list-item [routerLink]="['/billable-reports']" matTooltip="Billable Reports"
                    matTooltipPosition="right" routerLinkActive="active-list-item">
                    <mat-icon class="material-symbols-outlined material-icons-outlined">receipt_long</mat-icon>
                    <span [translate]="'Billable Reports'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Billable Reports
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EVENT_TASKS')">
                <a mat-list-item [routerLink]="['/tasks']" matTooltip="Tasks" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">task_alt</mat-icon>
                    <span [translate]="'Tasks'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Tasks
                    </span>
                </a>
            </mat-nav-list>
        </div>
        <div [ngClass]='appropriateClass'>
            <mat-nav-list>
                <a mat-list-item [routerLink]="['/settings']" matTooltip="Settings" matTooltipPosition="right"
                    routerLinkActive="active-list-item" *ngIf="authService.checkRoleBasedModuleAccess('SETTINGS')">
                    <mat-icon class="material-icons-outlined">settings</mat-icon>
                    <span [translate]="'Settings'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Settings
                    </span>
                </a>

                <a (click)="authService.isExpanded = !authService.isExpanded" mat-list-item>
                    <mat-icon *ngIf="authService.isExpanded">keyboard_double_arrow_left</mat-icon>
                    <mat-icon *ngIf="!authService.isExpanded">keyboard_double_arrow_right</mat-icon>
                    <span [translate]="'Collapse sidebar'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Collapse
                        sidebar</span>
                    <span slot="right" style="color: grey;font-size: small; margin: 0 0 0 auto;"
                        *ngIf="authService.isExpanded" class="menu-icon-spacing">
                        {{version }}</span>
                </a>
            </mat-nav-list>
        </div>
    </mat-sidenav>
    <!--#endregion -->

    <mat-sidenav-content>
        <mat-toolbar class="toolbar-height-50">
            <span>Billable Reports</span>
            <span class="toolbar-spacer"></span>
            <button mat-stroked-button color="primary" (click)="refreshBillableReports()">Reset</button>
        </mat-toolbar>


        <div class="container mat-elevation-z4">
            <mat-progress-bar *ngIf="isShowBillableReportsProgressBar" class="primary-color" mode="indeterminate">
            </mat-progress-bar>
            <mat-toolbar class="container-toolbar">
                <mat-toolbar-row class="container-toolbar-row">
                    <form [formGroup]="formGroup" style="width: 100%;display: contents;">

                        <mat-form-field appearance="outline"
                            style="font-size: 15px;margin: 22px 10px 0px 10px;width: 20%;">
                            <mat-label>Report Type</mat-label>
                            <mat-select disableOptionCentering required formControlName="reportType">
                                <mat-option *ngFor="let type of reportTypes" [value]="type.REPORT_ID">
                                    {{type.REPORT_NAME}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <button *ngIf="authService.checkRoleBasedModuleReadOnlyAccessBillable()"
                            matTooltip="Add" mat-icon-button class="primary-button-icon"
                            (click)="addNewReportType();$event.stopPropagation();">
                            <mat-icon class="material-icons-outlined">add</mat-icon>
                        </button>

                        <button *ngIf="authService.checkRoleBasedModuleReadOnlyAccessBillable()"
                            matTooltip="Edit" mat-icon-button
                            (click)="updateReportType(formGroup.controls.reportType.value);$event.stopPropagation();"
                            [disabled]="!formGroup.controls.reportType.value"
                            [ngClass]="!formGroup.controls.reportType.value ? '': 'primary-button-icon'">
                            <mat-icon class="material-icons-outlined">edit</mat-icon>
                        </button>

                        <mat-form-field appearance="outline"
                            style="font-size: 15px;margin: 22px 10px 0px 10px;width: 20%"
                            *ngIf="formGroup.controls.isCustom.value != true">
                            <mat-label>Reporting Snapshot</mat-label>
                            <input formControlName="snapshot" (click)="dp1.open()" matInput [matDatepicker]="dp1"
                                required [readonly]="true">
                            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                            <mat-datepicker disabled="false" #dp1></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" *ngIf="formGroup.controls.isCustom.value == true"
                            style="font-size: 15px;margin: 22px 10px 0px 10px;width: 18%;">
                            <mat-label>Start Date</mat-label>
                            <input formControlName="startDate" (click)="dp2.open()" matInput [matDatepicker]="dp2"
                                required [readonly]="true">
                            <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                            <mat-datepicker disabled="false" #dp2></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="outline" *ngIf="formGroup.controls.isCustom.value == true"
                            style="font-size: 15px;margin: 22px 10px 0px 10px;width: 18%">
                            <mat-label>End Date</mat-label>
                            <input formControlName="endDate" (click)="dp3.open()" matInput [matDatepicker]="dp3"
                                [min]="formGroup.controls.startDate.value" required [readonly]="true">
                            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                            <mat-datepicker disabled="false" #dp3></mat-datepicker>
                        </mat-form-field>

                        <mat-slide-toggle formControlName="isCustom" style="font-size: 15px;" color="primary">Custom
                            Reporting Period</mat-slide-toggle>

                        <button style="right: 15px;position: absolute;" mat-flat-button
                            [ngClass]="(formGroup.valid) ? 'primary-button' : ''" (click)="generateReport()"
                            [disabled]="!formGroup.valid">
                            Generate Report
                        </button>
                    </form>
                </mat-toolbar-row>

            </mat-toolbar>

            <div class="table-container" *ngIf="reportsLength > 0">
                <table mat-table [dataSource]="reportsDataSource" multiTemplateDataRows class="mat-elevation-z8">
                    <ng-container matColumnDef="TAG">
                        <th mat-header-cell *matHeaderCellDef> Tag </th>
                        <td mat-cell *matCellDef="let element"> {{element.TAG}} </td>
                        <td mat-footer-cell *matFooterCellDef style="color: rgba(0, 0, 0, .54);font-weight: bold;">
                            Total </td>
                    </ng-container>

                    <ng-container matColumnDef="TERMINAL">
                        <th mat-header-cell *matHeaderCellDef> Terminal </th>
                        <td mat-cell *matCellDef="let element"> {{element.TERMINAL}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>

                    <ng-container matColumnDef="LOCATION">
                        <th mat-header-cell *matHeaderCellDef> Location </th>
                        <td mat-cell *matCellDef="let element"> {{element.LOCATION}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>

                    <ng-container matColumnDef="EQUIPMENT_CATEGORY_ATTRIBUTE">
                        <th mat-header-cell *matHeaderCellDef> Equipment Category </th>
                        <td mat-cell *matCellDef="let element"> {{element.EQUIPMENT_CATEGORY_ATTRIBUTE}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>

                    <ng-container matColumnDef="COUNT_OF_UNITS">
                        <th mat-header-cell *matHeaderCellDef style="text-align: right !important;">
                            Count of Units
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align: right;"> {{element.COUNT_OF_UNITS}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef
                            style="text-align: right;font-weight: bold;color: rgba(0, 0, 0, .54);"> {{getTotalCost() |
                            number}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="tableColumns; sticky: true"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9999" style="color:rgb(228, 0, 43)">
                            <span *ngIf="reportsDataSource?.data?.length == 0 && !isShowBillableReportsProgressBar">
                                No reports found
                            </span>
                            <span *ngIf="
                            reportsDataSource?.data?.length == 0 &&
                            isShowBillableReportsProgressBar
                          ">
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
            <mat-toolbar class="container-toolbar" *ngIf="reportsLength > 0">
                <mat-toolbar-row class="container-toolbar-row">
                    <button style="left: 10px;" mat-flat-button class="primary-button" (click)=" downloadCsvFile()">
                        Download Report
                    </button>

                    <span class="example-spacer"></span>
                    <mat-paginator #paginator [pageSizeOptions]="[100, 500, 1000, 5000, 10000]" showFirstLastButtons>
                    </mat-paginator>
                </mat-toolbar-row>
            </mat-toolbar>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>