export enum ActionType {
  Add = 1,
  Edit = 2,
}

export class AppConstants {
  public APP_RELEASE_NUMBER = "1.0.85";
  public APP_RELEASE_DATE = "2024-12-21_17-12-12";
  public DATE_FORMAT = 'MMM dd, yyyy hh:mm a';
  public DATE_ONLY_FORMAT = 'MMM dd, yyyy';
  public INITIAL_PAGINATOR_LIMIT = 10;
  public LOGIN_URL = '/login?device=browser&app=admin';
  public FORGOT_PASSWORD = '/forgotpassword';
  public RESET_PASSWORD = '/resetpassword';
  public GET_CUSTOMERS_URL = '/customers';
  public UPDATE_CUSTOMER_URL = '/customer';
  public GET_USERS_URL = '/users';
  public UPDATE_USER_URL = '/user';
  public GET_USER_ACCESS_MODULES_URL = '/modules';
  public GET_SERVICE_REQUESTS_URL = '/servicerequests';
  public UPDATE_SERVICE_REQUESTS_URL = '/servicerequest';
  public GET_SERVICE_REQUESTS_LOGS_URL = '/changelogs';
  public GET_SERVICE_REQUESTS_FILTER_DATA_URL = '/filterdata';
  public GET_SERVICE_REQUESTS_EQUIPMENT_DETAILS_URL = '/equipment';
  public GET_AUDITS_URL = '/audits';
  public GET_LOACTIONS_URL = '/location';
  public GET_AUDIT_SCHEDULES_URL = '/auditschedules';
  public UPDATE_AUDIT_SCHEDULES_URL = '/auditschedule';
  public GET_LOCATIONS_HEIRARCHY_URL = '/locationheirarchy';
  public GET_EQUIPMENTS_BY_LOCATION_ID_URL = '/equipments';
  public GET_EQUIMENT_CATEGORIES_URL = '/equipmentcategories';
  public UPDATE_EQUIPMENT_CATEGORIES_URL = '/equipmentcategory';
  public GET_LOCATION_CATEGORIES_URL = '/locationcategory';
  public GET_CATEGORIES_BY_LOCATION_ID_URL = '/equipments';
  public GET_CUSTOMIZATION_URL = '/customization';
  public GET_PARTS_URL = '/parts';
  public UPDATE_PART_URL = '/part';
  public INVENTORY_URL = '/inventory';
  public GET_INVENTORY_LOCATIONS_URL = '/locations';
  public GET_PART_MANUFACTURERS_URL = '/manufacturers';
  public LOPA_URL = '/lopa';
  public GET_FLEETS_URL = '/fleets';
  public DOWNLOAD_SAMPLE_DOC_URL = '/sampledoc';
  public FLEET_DOCUMENT_URL = '/fleetdocument';
  public GET_LOPA_INVENTORY_URL = '/lopainventory';
  public GET_LOPA_INITIAL_DATA_URL = '/lopainitialdata';
  public GET_SERVICE_REQUEST_MESSAGE_URL = '/message';
  public CONVERT_TO_SERVICE_REQUEST_URL = '/converttoservicerequest';
  public CHOICE_ADD_URL = '/choice';
  public EXTENSIONS_ADD_URL = '/extension';
  public SCHEDULED_SERVICE_REQUEST_URL = '/servicerequestschedule';
  public CONFIGURATION_URL = '/config';
  public BUDGET_URL = '/budget';
  public TEST_EMAIL_URL = '/testemail';
  public DELETE_AUDIT = '/audit';
  public GET_PICK_LIST_BY_REQUEST_ID = '/picklist';
  public GET_EQUIPMENT_CATEGORY_ATTRIBUTES_BY_CATEGORY_ID = '/equipmentcategoryattributes';
  public ADD_NEW_ATTRIBUTE_URL = '/equipmentcategoryattribute';
  public GET_FY_DATE_RANGE_URL = '/fydaterange';
  public DELETE_ATTRIBUTE_URL = '/equipmentcategoryattribute';
  public GET_INVOICES_URL = '/invoice';
  public GET_BOM_EQUIPMENT_CATEGORY_MAP_URL = '/bomequipmentcategorymap';
  public GET_SWITCH_TENANTS_URL = '/switchtenant';
  public PART_ORDER_URL = '/partorder';
  public RECEIVE_PART_ORDER_URL= '/receivepartorder';
  public GET_BILLABLE_UNIT_REPORT_URL = '/billableunitreport';
  public GENERATE_BILLABLE_REPORTS_URL= '/generatebillableunitreport';
  public GET_EVENT_TASKS = '/eventtasks';
  public GET_TASK_TYPES = '/eventtasktypes';
  public DELETE_TASK_TYPE_URL = '/eventtasktype';
}
